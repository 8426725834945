/*
 * Primary Responsive Navigation
 *
 * @desc Handles the primary nav
 * =========================================================================== */

const BP = BP || {};
BP.Modules = BP.Modules || {};

/* ==========================================================================
   Responsive Nav
   ========================================================================== */

BP.Modules.responsiveNav = (function ($, window, document) {
  "use strict";

  const $body = $("body");
  let config = {
    burgerIcon: $(".js-navigation-toggle"),
    navigationPane: $(".js-navigation"),
    dropdownToggle: $(".js-dropdown-toggle"),
    // dropdownPane: $(".js-dropdown-pane"),
    activeClass: "is-open",
    animationSpeed: 250
  };

  /**
   * Private methods
   *
   * Private methods start with an underscore `_methodName`
   * This helps visually identify private functions vs public.
   */

  function _setupClickHandlers (config) {
    // Event Handler for burger icons
    config.burgerIcon.on("click", function () {
      _handleBurgerToggle($(this));
    });

    // Evebt handler for navigation items
    config.dropdownToggle.on("click", function () {
      _handleDropdownToggle($(this));
    });

    // Closes open nav when clicking outside of dropdown
    $body.on("click", function (event) {
      if (!$(event.target).closest(config.navigationPane).length) {
        config.dropdownToggle.next(".js-dropdown-pane").slideUp(config.animationSpeed);
        config.dropdownToggle.removeClass(config.activeClass);
      }
    });
  }

  // Handles the animation for the burger icon, adding/removing classes etc.
  function _handleBurgerToggle (currentTarget) {
    if ($(currentTarget).hasClass(config.activeClass)) {
      $(currentTarget).removeClass(config.activeClass);
      config.navigationPane.slideUp(config.animationSpeed);
    } else {
      $(currentTarget).addClass(config.activeClass);
      config.navigationPane.slideDown(config.animationSpeed);
    }
  }

  // Handles the animation for the drowndown, adding/removing classes etc.
  function _handleDropdownToggle (currentTarget) {
    if (!$(currentTarget).hasClass("is-open")) {
      config.dropdownToggle.next(".js-dropdown-pane").slideUp(config.animationSpeed);
      config.dropdownToggle.removeClass("is-open");

      $(currentTarget).addClass("is-open");
      $(currentTarget).next(".js-dropdown-pane").slideDown(config.animationSpeed);
    } else {
      config.dropdownToggle.next(".js-dropdown-pane").slideUp(config.animationSpeed);
      config.dropdownToggle.removeClass("is-open");
    }
  }

  /**
   * Public methods
   *
   * Public methods are returned/exposed at the bottom of the script.
   */

  function setupNav (newConfig) {
    if (typeof newConfig === "object") {
      config = newConfig;
      // console.log(config.option);
    }
    _setupClickHandlers(config);
  }

  /**
   * Expose public methods
   */

  return {
    init: setupNav
  };
})(jQuery, window, document);

/* ==========================================================================
   Modals
   ========================================================================== */

BP.Modules.modal = (function ($, window, document) {
  "use strict";

  // const $body = $("body");
  let config = {
    closeText: '',
    fadeDuration: 200,
    fadeDelay: .8
  };

  function _setupModals (config) {
    $('[data-modal]').on('click', function(event) {
      // $(this).modal(config);
      event.preventDefault();

      if ($(this).data('modal') !== '') {
        $($(this).data('modal')).modal(config);
      } else {
        $(this).modal(config);
      }

      // return false;
    });

  }

  function initModals (newConfig) {
    if (typeof newConfig === "object") {
      config = newConfig;
      // console.log(config.option);
    }
    _setupModals(config);
  }

  return {
    init: initModals
  };
})(jQuery, window, document);

/* ==========================================================================
   Tabs
   ========================================================================== */

BP.Modules.tabs = (function ($, window, document) {
  "use strict";

  // const $body = $("body");
  // let config = {
  //   closeText: '',
  //   fadeDuration: 200,
  //   fadeDelay: .8
  // };

  function _setupTabs () {
    // $('[data-tab]').on('click', function (e) {
    //   $(this)
    //     .addClass('is-active')
    //     // .parents('.bpTabs')
    //     .siblings('[data-tab]')
    //     .removeClass('is-active')
    //   // $(this)
    //     .parents('.bpTabs')
    //     .find('[data-tab-panel=' + $(this).data('tab') + ']')
    //     .addClass('is-active')
    //     .fadeIn('fast')
    //     .siblings('[data-tab-panel]')
    //     .removeClass('is-active')
    //     .hide();
    //   e.preventDefault();
    //
    //   console.log('hi');
    // });

    // $('#responsiveTabsDemo').responsiveTabs({
    $('[data-responsive-tabs]').responsiveTabs({
      startCollapsed: 'accordion',
      animation: 'fade',
      animationQueue: true,
      duration: 250,
      classes: {
        stateDefault: 'is-default',
        stateActive: 'is-active',
        stateDisabled: 'is-disabled',
        stateExcluded: 'is-excluded',
        container: 'js-bpTabs',
        ul: 'js-bpTabs-nav',
        tab: 'js-bpTabs-tab',
        anchor: 'js-bpTabs-anchor',
        panel: 'js-bpTabs-panel',
        accordionTitle: 'bpTabs-accordionTitle'
      }
    });

  }

  function initTabs () {
    // if (typeof newConfig === "object") {
    //   config = newConfig;
    //   // console.log(config.option);
    // }
    _setupTabs();
  }

  return {
    init: initTabs
  };
})(jQuery, window, document);

/* ==========================================================================
   Product Picker
   ========================================================================== */

BP.Modules.productPicker = (function ($, window, document) {
  "use strict";

  const tabs = {
    tab: $(".productColumn-tab"),
    activeClass: "is-active"
  };

  // Handles selected tab
  function _handleTabs ($currentTarget) {
    // Remove active class for tab button
    $currentTarget.parent(".productColumn-tabs").find(".productColumn-tab").removeClass(tabs.activeClass);
    // Add active class to clicked tab button
    $currentTarget.addClass(tabs.activeClass);

    // Get data attr
    let tabContentName = $currentTarget.data("tab-content");
    // Find matching tab content with selected name
    let tabContentElement = $currentTarget.parent(".productColumn-tabs").parent(".productColumn").find(".js-tabPane-" + tabContentName);
    // Remove active tab content classes
    $currentTarget.parent(".productColumn-tabs").parent(".productColumn").find(".productColumn-tabPane").removeClass(tabs.activeClass);
    // Add active class for tab content
    tabContentElement.addClass(tabs.activeClass);
  }

  /**
   * Public methods
   *
   * Public methods are returned/exposed at the bottom of the script.
   */

  function setupTabs () {
    // Event Handler for tabs on product picker
    tabs.tab.on("click", function (e) {
      _handleTabs($(this));
      e.preventDefault();
    });
  }

  /**
   * Expose public methods
   */

  return {
    init: setupTabs
  };
})(jQuery, window, document);

/* ==========================================================================
 Smooth Scroll To ELement
 ========================================================================== */

BP.Modules.smoothScrollToElement = (function ($, window, document) {
  "use strict";

  let config = {
    body: $('html, body'),
    clickElement: $(".js-scrollToElement")
  };

  /**
   * Public methods
   *
   * Public methods are returned/exposed at the bottom of the script.
   */

  function initScroll () {
    config.clickElement < 0 || config.clickElement.click(function (e) {
      let speed = $(this).data("speed");
      let easing = $(this).data("easing");
      if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
        var t = $(this.hash);
        if (t = t.length ? t : $("[name=" + this.hash.slice(1) + "]"), t.length) return config.body.animate({
          scrollTop: t.offset().top
        }, speed, easing), !1
      }
    });
  }

  /**
   * Expose public methods
   */

  return {
    init: initScroll
  };
})(jQuery, window, document);

/* ==========================================================================
   Lets do dis.
   ========================================================================== */

$(function () {
  BP.Modules.responsiveNav.init();
  BP.Modules.modal.init();
  BP.Modules.tabs.init();
  BP.Modules.smoothScrollToElement.init();
  // BP.Modules.productPicker.init();
});
